
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































































































































































































































.organization-permissions {
  .el-checkbox__label {
    @apply tw-p-0;
  }

  &-pagination {
    @include pagination;
  }

  .el-collapse-item {
    @apply tw-mb-space-2 tw-w-full;

    &.is-active {
      border-left: theme('spacing.space-3px');

      @apply tw-border-solid tw-border-jb-indigo tw-rounded;
    }

    .chevron-icon {
      @apply tw-transform tw--rotate-90 tw-duration-300;

      &.is-active {
        @apply tw-rotate-90;
      }
    }
  }

  .el-collapse-item__content {
    @apply tw-p-0 tw-rounded;
  }

  .resources-section .el-collapse-item {
    @apply tw-border-solid tw-border tw-border-jb-grey-50 tw-rounded;
  }

  .campaign-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr))
  }

  .resources-section-grid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  .resources-section .el-collapse-item__content {
    @apply tw-p-space-4;
  }

  .el-collapse-item__header {
    @apply tw-p-space-4;
  }

  .checkbox {
    @apply tw-flex tw-items-center tw-py-space-2px;
  }

  .subtitle-resource {
    @include line-clamp(2);
  }

  .campaign-checkbox {
    min-width: theme('spacing.28');
  }
}
